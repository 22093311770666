import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../01_atoms/Button/Button';
import Icon from '../../01_atoms/Icon/Icon';
import SearchBar from '../../01_atoms/SearchBar/SearchBar';

interface titleProps {
  title: string;
  searchBar?: boolean;
  buttonLabel?: string;
  buttonAction?: (clicked: string) => void | undefined;
}

function Header(props: titleProps) {
  const { title, searchBar, buttonLabel, buttonAction } = props;
  const navigate = useNavigate();

  function handleClicked() {
    const path = window.location.pathname;
    const splittedPath = path.split('/');
    if (splittedPath[1] === 'admin') navigate('/admin');
    else navigate('/');
  }

  return (
    <div className="m-header">
      <button type="button" className="m-header__icon-title-button" onClick={handleClicked}>
        <Icon />
        <h1 className="m-header__title">{title}</h1>
      </button>
      {buttonAction && buttonLabel && !searchBar ? (
        <Button label={buttonLabel} type="primary" setClicked={buttonAction} />
      ) : null}
      {buttonAction && buttonLabel && searchBar ? (
        <div className="m-header__button-search">
          <Button label={buttonLabel} type="primary" setClicked={buttonAction} /> <SearchBar />
        </div>
      ) : null}
      {searchBar && !buttonLabel ? <SearchBar /> : null}
    </div>
  );
}

Header.defaultProps = {
  searchBar: false,
  buttonLabel: '',
  buttonAction: null,
};

export default Header;
