import React, { useState, useEffect } from 'react';
import Button from '../../01_atoms/Button/Button';
import InputField from '../../01_atoms/InputField/InputField';

interface RfidInputProps {
  setRfidPopButton: (value: string) => void;
  rfidValue?: string | undefined;
}

function RfidInput(props: RfidInputProps) {
  const { rfidValue, setRfidPopButton } = props;
  const [rfid, setRfid] = useState('');

  useEffect(() => {
    if (rfidValue) setRfid(rfidValue);
  });

  return (
    <div className="m-isbn-search">
      <InputField labelName="RFID" type="text" placeholder="RFID" value={rfid} onChange={setRfid} />
      <Button label="Scannen" type="primary-monochrome" setClicked={setRfidPopButton} />
    </div>
  );
}

RfidInput.defaultProps = {
  rfidValue: '',
};

export default RfidInput;
