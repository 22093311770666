import React from 'react';
import bookOverview from '../../../types/BookOverviewType';
import TextLabel from '../../01_atoms/TextLabel/TextLabel';

interface BookDetailsProps {
  data: bookOverview;
}

function BookDetails(props: BookDetailsProps) {
  const { data } = props;

  let image = 'http://serc.cl/wp-content/uploads/2018/02/book-cover-placeholder.jpg';
  let status = 'beschikbaar';

  if (data.coverBookUrl) image = data.coverBookUrl;
  if (!data.available) {
    const firstNameLetter = `${data.currentLender?.split(' ').pop()} `;
    const lastName = data.currentLender?.substring(0, data.currentLender?.lastIndexOf(' '));
    const fullName = firstNameLetter + lastName;

    status = `Uitegeleend door ${fullName}`;
  }

  return (
    <div className="o-book-details__container">
      <div className="o-book-details__details">
        <TextLabel text={data.author} size="medium" />
        <TextLabel text={data.description} size="medium" />
        <div className="o-book-details__info">
          <div className="o-book-details__info-item">
            <TextLabel text="Eigenaar" size="medium" />
            <div className="o-book-details__info-item__border">
              <TextLabel text={data.owner} size="medium" />
            </div>
          </div>
          <div className="o-book-details__info-item">
            <TextLabel text="Status" size="medium" />
            <div className="o-book-details__info-item__border">
              <TextLabel text={status} size="medium" />
            </div>
          </div>
        </div>
      </div>
      <img className="o-book-details__image" src={image} alt="Boekomslag" />
    </div>
  );
}

export default BookDetails;
