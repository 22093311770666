const url = process.env.REACT_APP_BACKEND_URL;

export const Get = async (endpoint: string) => {
  const res = await fetch(`${url}${endpoint}`);
  const jsonData = await res.json();
  return jsonData;
};

export const Delete = async (endpoint: string) => {
  const res = await fetch(`${url}${endpoint}`, { method: 'DELETE' });
  return res.status;
};

export const Post = async (endpoint: string, data: unknown) => {
  const res = await fetch(`${url}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(data),
  }).then((r) => r.json());
  // .catch((error) => console.error({ error }));

  return res;
};

export const Put = async (endpoint: string, data: unknown) => {
  const res = await fetch(`${url}${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
  // .catch((error) => console.error({ error }));

  return res;
};
