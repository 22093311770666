import React, { useEffect, useState } from 'react';
import { Get } from '../../../constants/dataAcces';
import BookOverview from '../../../types/BookOverviewType';
import OverviewTemplate from '../../04_templates/OverviewTemplate/OverviewTemplate';

function AdminOverviewPage() {
  const [books, setBooks] = useState<BookOverview[]>();

  const getBooks = async () => {
    const data: BookOverview[] = await Get(`/books`);
    setBooks(data);
  };

  useEffect(() => {
    getBooks();
    document.title = 'Sweet Bibi - Overzicht';
  }, []);

  return (
    <main>
      <OverviewTemplate data={books} />
    </main>
  );
}

export default AdminOverviewPage;
