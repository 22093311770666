import React, { useEffect, useState } from 'react';
import BookOverview from '../../../types/BookOverviewType';
import Header from '../../02_molecules/Header/Header';
import PopUpViewAddRfid from '../../03_organisms/PopUpViewAddRfid/PopUpViewAddRfid';
import SubtitleBookOverview from '../../03_organisms/SubtitleBookOverview/SubtitleBookOverview';

interface adminOverviewProps {
  data?: BookOverview[];
}

function AdminOverviewTemplate(props: adminOverviewProps) {
  const { data } = props;
  const [showPopupRfid, setShowPopUpRfid] = useState(false);

  const showRfidPopUp = () => {
    setShowPopUpRfid(true);
  };

  useEffect(() => {
    document.title = 'Sweet Bibi - Admin Overzicht';
  }, []);

  return (
    <div>
      {showPopupRfid ? (
        <PopUpViewAddRfid setShowPopUpRfid={setShowPopUpRfid} navigateUrl="/admin/add" />
      ) : null}
      <header>
        <Header
          title="Sweet Bibi - Admin"
          searchBar
          buttonLabel="Boek toevoegen"
          buttonAction={showRfidPopUp}
        />
      </header>
      <SubtitleBookOverview subtitle="Uitgeleend" books={data} admin />
      <SubtitleBookOverview subtitle="Beschikbaar" books={data} admin />
    </div>
  );
}

AdminOverviewTemplate.defaultProps = {
  data: [],
};

export default AdminOverviewTemplate;
