import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IsbnType from '../../../types/IsbnType';
import scanRfidType from '../../../types/ScanRfidType';
import Button from '../../01_atoms/Button/Button';
import Subtitle from '../../01_atoms/Subtitle/Subtitle';
import Header from '../../02_molecules/Header/Header';
import IsbnSearch from '../../02_molecules/IsbnSearch/IsbnSearch';
import RfidInput from '../../02_molecules/RfidInput/RfidInput';
import AddBookForm from '../../03_organisms/AddBookForm/AddBookForm';
import PopUpViewAddRfid from '../../03_organisms/PopUpViewAddRfid/PopUpViewAddRfid';

function AdminAddBookTemplate() {
  const [isbnData, setIsbnData] = useState<IsbnType>();
  const [isbn, setIsbn] = useState('');
  const location = useLocation();

  // RFID
  const [showPopupRfid, setShowPopUpRfid] = useState(false);
  const [rfid, setRfid] = useState<string>('');

  const showPopupRfidFunction = () => {
    setShowPopUpRfid(true);
  };

  useEffect(() => {
    const rfidPopUp = location.state as scanRfidType;
    if (rfidPopUp) setRfid(rfidPopUp.data);
    window.addEventListener('RfidScanned', (e) => {
      setRfid((e as CustomEvent).detail.rfid);
    });
    return () => {
      // Remove event listeners
      window.removeEventListener('RfidScanned', (e) => {
        setRfid((e as CustomEvent).detail.rfid);
      });
    };
  }, []);
  return (
    <div>
      <header>
        <Header title="Sweet Bibi - Admin" />
      </header>
      {showPopupRfid ? <PopUpViewAddRfid setShowPopUpRfid={setShowPopUpRfid} /> : null}
      <Subtitle title="Voeg een nieuw boek toe" />
      <div className="t-admin-add-book__isbn-rfid-container">
        <RfidInput rfidValue={rfid} setRfidPopButton={showPopupRfidFunction} />
        <IsbnSearch setData={setIsbnData} setIsbnNumber={setIsbn} />
      </div>
      <AddBookForm isbnData={isbnData} isbnNumber={isbn} rfidNumber={rfid} />
      <div className="t-admin-add-book__submit ">
        <Button label="Ja" type="primary" submit submitForm="add-book-form" setClicked={() => ''} />
        <Button
          label="Annuleren"
          type="secondary"
          setClicked={() => {
            window.location.href = '../admin';
          }}
        />
      </div>
    </div>
  );
}

export default AdminAddBookTemplate;
