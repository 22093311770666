import React, { useEffect, useState } from 'react';
import { Get } from '../../../constants/dataAcces';
import IsbnType from '../../../types/IsbnType';
import Button from '../../01_atoms/Button/Button';
import InputField from '../../01_atoms/InputField/InputField';

interface isbnSearch {
  setData: (value: IsbnType) => void;
  setIsbnNumber: (value: string) => void;
  isbnValue?: string;
}

function IsbnSearch(props: isbnSearch) {
  const { setData, setIsbnNumber, isbnValue } = props;
  const [isbn, setIsbn] = useState('');
  useEffect(() => {
    if (isbnValue) setIsbn(isbnValue);
  }, [isbnValue]);
  const searchIsbn = async () => {
    setIsbnNumber(isbn);
    try {
      const data = await Get(`/books/isbn/${isbn}`);
      if (!data.title) {
        throw new Error();
      }
      const e = new CustomEvent('ShowAlert', {
        detail: {
          label: 'Boek is successvol gevonden',
          type: 'Success',
        },
      });
      window.dispatchEvent(e);
      const isbnData: IsbnType = data;
      setData(isbnData);
    } catch (error) {
      const e = new CustomEvent('ShowAlert', {
        detail: {
          label: 'Boek is niet gevonden, vul het formulier aan',
          type: 'Warning',
        },
      });
      window.dispatchEvent(e);
    }
  };
  return (
    <div className="m-isbn-search">
      <InputField labelName="ISBN" type="text" placeholder="ISBN" value={isbn} onChange={setIsbn} />
      <Button label="Zoeken" type="primary-monochrome" setClicked={searchIsbn} />
    </div>
  );
}

IsbnSearch.defaultProps = {
  isbnValue: '',
};

export default IsbnSearch;
