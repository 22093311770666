import React from 'react';
import ReturnButton from '../../01_atoms/ReturnButton/ReturnButton';
import TextLabel from '../../01_atoms/TextLabel/TextLabel';

interface ReturnIsbnProps {
  isbn: string;
}

function ReturnIsbn(props: ReturnIsbnProps) {
  const { isbn } = props;

  return (
    <div className="m-return-isbn">
      <div className="m-return-isbn__left-right">
        <ReturnButton />
      </div>
      <TextLabel text={isbn} size="medium" />
      <div className="m-return-isbn__left-right" />
    </div>
  );
}

export default ReturnIsbn;
